@charset "utf-8";

header {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
}
header h1 {
  width: 50%;
}
header .nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header .nav li {
  font-size: 16px;
  font-weight: bold;
  margin-left: 30px;
}
header .nav li a:hover {
  color: #d2d2d2;
}
@media screen and (max-width:768px) { 
  header {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
  }
  header h1 {
    width: 50%;
  }
  header .nav {
    display: none;
  }
  header .nav li {
    font-size: 16px;
    font-weight: bold;
    margin-left: 30px;
  }
  header .nav li a:hover {
    color: #d2d2d2;
  }
}