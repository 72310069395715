@charset "utf-8";
footer {
  background: #000;
  color: #fff;
}
footer .footer-contents {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 50px;
}
footer .logo-area {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
footer h1 {
  width: 23%;
}
footer .nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
footer .nav li {
  font-size: 16px;
  font-weight: bold;
  margin-left: 30px;
}
footer .nav li a {
  color: #fff;
}
footer .nav li a:hover {
  color: #646464;
}
footer .copyright {
  font-size: 1.2rem;
  font-weight: bold;
  font-family: ‘Poppins’,sans-serif;
}
@media screen and (max-width:768px) { 
  footer .footer-contents {
    max-width: 1280px;
    margin: 0 auto;
    display: block;
    padding: 40px 50px;
  }
  footer .logo-area {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  footer h1 {
    width: 23%;
  }
  footer .nav {
    display: block;
  }
  footer .nav li {
    font-size: 16px;
    font-weight: bold;
    margin-left: 30px;
  }
  footer .nav li a {
    color: #fff;
  }
  footer .nav li a:hover {
    color: #646464;
  }
  footer .copyright {
    font-size: 1.2rem;
    font-weight: bold;
    font-family: ‘Poppins’,sans-serif;
  }
}