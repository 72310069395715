@charset "utf-8";

// Settings
@import "settings/variables";
@import "settings/functions";
@import "settings/mixins";

// Plugins
@import "~html5-reset";
// @import "~normalize.css";
// @import "~sanitize.css";

// Base
@import "base/default";
@import "base/general";

// Components
@import "components/components";

// Layouts
@import "layouts/header";
@import "layouts/main";
@import "layouts/footer";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap');
